import React from 'react';

import { HelpArticle, SubTitle } from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';

import logo from 'img/help/integrations/gatsby.png';

const Content = () => {
  return (
    <div>
      <SubTitle>LiveSession and Gatsby integration</SubTitle>
      <p>
        LiveSession provides a{' '}
        <a
          href="https://www.gatsbyjs.org/packages/@livesession/gatsby-plugin-livesession/"
          target="_blank"
          rel="noopener noreferrer"
        >
          plugin
        </a>{' '}
        that lets you install script even easier.
      </p>
      <p>In your project run </p>
      <Code style={{ margin: '1rem 0' }}>npm i @livesession/gatsby-plugin-livesession</Code>{' '}
      <p>or</p>
      <Code style={{ margin: '1rem 0 1.5rem' }}>
        yarn add @livesession/gatsby-plugin-livesession
      </Code>
      <p>
        Go to <strong>gatsby-config.js</strong> file and add plugin, everything what you need is{' '}
        <strong>trackID</strong>:
      </p>
      <Code margin="4rem 0 3rem" copy lang="javascript">
        {`// gatsby-config.js
 plugins: [
    {
        resolve: '@livesession/gatsby-plugin-livesession',
        options: {
          trackID: YOUR_LIVESESSION_TRACKID, // Required, string
          keystrokes: true || false, // Optional, default to false
          rootHostname: '.example.com', // Optional
        },
    },
 ];`}
      </Code>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        Plugin adds script only in production mode.
      </div>
      <p>
        If you want to know more about options you can pass into config, go to{' '}
        <a
          href="https://developers.livesession.io/javascript-api/configuration/"
          target="_blank"
          rel="noopener noreferrer"
        >
          LiveSesion Developers page
        </a>
        .
      </p>
    </div>
  );
};

const related = [
  {
    link: '/help/how-to-check-if-my-tracking-script-works/',
    title: 'How to check if my tracking script works?',
    description: 'Check if your tracking code is installed correctly.',
  },
  {
    link: '/help/how-livesession-script-works/',
    title: 'How LiveSession script works?',
    description: 'Undarstand what our tracking code tracks.',
  },
];

export const frontmatter = {
  metaTitle: 'How to install LiveSession on Gatsby site?',
  metaDescription: 'Install script even easier with our Gatsby plugin.',
  canonical: '/help/gatsby-integration/',
  logoStyle: { maxWidth: 150 },
  logo,
};

const Wrapped = HelpArticle(Content);

export default () => (
  <Wrapped related={related} section="Get Started" title={frontmatter.metaTitle} {...frontmatter} />
);
